import { combineReducers } from "redux";

import {
	CREATE_AUTHOR,
	REQUEST_FETCH_PROFILE,
	CREATE_AUTHOR_SUCCESS,
	CREATE_AUTHOR_FAILURE,
	CLEAR_STATE,
} from "../constants";
  
const initialState = {
	author: {
		authorer_id: "",
	},
	isFetching: false,
	isUpdating: false,
	isUpdated: false,
	successResponse: {
		message: "",
		ok: false,
		author_id: "",
		author_time: "",
	},
}

const author = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_AUTHOR:
			return {
				...state,
				isFetching: true,
			}
		case CREATE_AUTHOR_SUCCESS:
			return {
				...state,
				successResponse: action.payload,
				isUpdating: false,
			}    
		case CREATE_AUTHOR_FAILURE:    
			return {
				...state,
				isUpdating: false,

			}       
		case CLEAR_STATE:
			return {
				...initialState,
			}
		default:
			return state;
	}
};

const isFetching = (state = initialState.isFetching, action) => {
	switch (action.type) {
		case CREATE_AUTHOR: 
		case REQUEST_FETCH_PROFILE:
			return true;
		case CREATE_AUTHOR_SUCCESS:
		case CREATE_AUTHOR_FAILURE:
		case CLEAR_STATE:
			return false;
		default:
			return state;
	}
};

export default combineReducers({
	author,
	isFetching,
});

export const selectIsFetching = state => state.isFetching;
export const selectIsUpdating = state => state.isUpating;
export const selectIsUpdated = state => state.isUpdated;
export const selectAuthorID = state => state.author.successResponse.author_id;
export const selectAuthorSuccessOk = state => state.author.successResponse.ok;

import React from 'react';
import { Paper } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
	{ label: 'Image 1', imgPath: 'https://i.ibb.co/YtpRrvR/1000001573.jpg' },
	{ label: 'Image 2', imgPath: 'https://i.ibb.co/BKnnpW7/IMG-20240501-WA0157-1.jpg' },
	{ label: 'Image 3', imgPath: 'https://i.ibb.co/FDrW57Y/IMG-20240501-WA0137.jpg' },
	{ label: 'Image 4', imgPath: 'https://i.ibb.co/QpQKfxf/1000001552.jpg' },
	{ label: 'Image 5', imgPath: 'https://i.ibb.co/S7Wn8kp/facebook-1716740792585-7200532773304850169.jpg' },
];

const styles = {
	container: {
		width: '100%',
		position: 'relative',
		paddingTop: '56.25%' /* 16:9 Aspect Ratio */,
		paddingX:'15rem'
	},
  	imageWrapper: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
  	},
  	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
  },
};

const Photos = () => (
	<AutoPlaySwipeableViews>
		{images.map((step, index) => (
			<Paper key={index} style={styles.container}>
				<div style={styles.imageWrapper}>
					<img src={step.imgPath} alt={step.label} style={styles.image} />
				</div>
			</Paper>
		))}
	</AutoPlaySwipeableViews>
);

export default Photos
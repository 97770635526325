import React from "react";
import {
    Typography,
    Divider,
    Box,
    Button,
} from "@mui/material";
import Link from '@mui/material/Link';

import Logo from "../../Constants/Images/ARDENT_LOGO.png"
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
    return(
			<Box 
                style={{
                    width:'100%',
                    backgroundColor:'#B0D9B1',
                    fontFamily: "roboto serif",
                    display:"flex", 
                    flexDirection:"column", 
                    justifyContent:"center", 
                    alignItems:"center", 
                    margin:"0 auto"
                }} 
            >
				<Box 
                    sx={{
						display:'flex',
						flexDirection:"row",
						justifyContent:'space-around',
						alignItems:"center",
						margin:"0 auto",
						marginTop:"80px",

						"@media (max-width:600px)": {

							fontSize: "1rem",
							flexDirection:'column',
						},                    
                    }}
                >
                    <Box 
						sx={{
							display:'flex',
							flexDirection:"row",
							justifyContent:"flex-start",
							alignItems:"center",
							margin:"0 auto",
							"@media (max-width:600px)": {

								fontSize: "1rem",
								flexDirection:'column',
								justifyContent:'center'
							},	
                        }}>
                            <Box 
								sx={{
									flexGrow: '1', 
									backgroundcolor:'blue', 
									paddingX:'1rem' 
								}}
							>
                            	<img  
									sx={{
										width:4,
										height:4,
										padding:4,
									}} 
									src={Logo} 
									width={100} 
									height={50} 
									alt="Logo of Ardent Programmers"
								/>
                            </Box>
                            <Divider orientation="vertical" color='black'  flexItem />
                            <Typography 
								sx={{
									flexWrap:'wrap', 
									textAlign:'center', 
									padding:'.2rem', 
									paddingX:'1rem'
								}}
							>
								We design problems to make thinking programmers
							</Typography>
                        </Box>
                        <Box 
                            sx={{
                                display:'flex',
                            	flexDirection:"row",
                            	justifyContent:"space-between",
								alignItems:"center",
								margin:"0 auto",
								width:"20dvh",
								marginY:'2rem',
									"& svg:hover": {
										color: "Teal",
										transform: "translateX(5px)",
										transition: "all 400ms",
									},
                            }}
                        >
							<Link href="https://www.facebook.com/ardentprogrammerscommunity" underline="none">
                            	<FacebookIcon sx={{ fontSize: 40 }} />
							</Link>	
							<Link href="https://www.youtube.com/channel/UC6C0cSL0Sp1Co4Czms6b74g" underline="none">
                            	<YouTubeIcon sx={{ fontSize: 40 }} />
							</Link>	
                            {/*<InstagramIcon sx={{ fontSize: 40 }} />*/}
                        </Box>
                    </Box>
					<Box 
						style={{
							display:'flex',
							flexDirection:"column",
							justifyContent:"center",
							alignItems:"center",
							margin:"0 auto",
						}}
					>
						
						{/*<Button>About Us</Button>*/} 
						<Link href="https://aubichol.com/" underline="none">
							<Button>Company </Button>
						</Link>
						{/*<Button>Contact Us </Button>*/}		
					</Box>
                <Box>  
            </Box>
		</Box>
    )
}

export default Footer;
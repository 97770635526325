import { combineReducers } from "redux";

import {
	FETCH_CONTEST_LIST,
	UPDATE_CONTEST_LIST_SUCCESS,
	UPDATE_CONTEST_LIST_FAILURE,

	RESET_LISTS,
	CLEAR_STATE,
	LOAD_CONTEST_LIST_SUCCESS
} from "../constants";

const dummyContest = {
	name: "",
	image_url: "",
	id: "",
}

const initialState = {
	contestList: [dummyContest],
	isFetching: false,
	isUpdating: false,
	isUpdated: false,
}

const list = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CONTEST_LIST:
			return {
				...state,
				isFetching: true,
				isUpdating: true,
			}
		case LOAD_CONTEST_LIST_SUCCESS:
			return {
				...state,
				contestList: action.payload,
			}
		case UPDATE_CONTEST_LIST_SUCCESS:
			return {
				...state,
				isUpdating: false,
				isFetching: false,
				isUpdated: true,
			}    
		case UPDATE_CONTEST_LIST_FAILURE:    
			return {
				...state,
				isUpdating: false,
				isFetching: false,
				isUpdated: false,

			}       
		case RESET_LISTS:
			return {
				isUpdating: false,
				isFetching: false,
			}
		case CLEAR_STATE:
			return {
				...initialState,
			}
		default:
			return state;
	}
};

export default combineReducers({
	list,
});

export const selectContestListIsFetching = state => state.list.isFetching;
export const selectContestListIsUpdating = state => state.list.isUpating;
export const selectContestListIsUpdated = state => state.list.isUpdated;
export const selectContestList = state => state.list.contestList || [];

export const selectContestFromList = (state, id) => {
	const contests = selectContestList(state);
	for (let i = 0 ; i < contests.length; i ++ ) {
		if( contests[i].id === id ) {
			return contests[i];
		}
	}
	return dummyContest;
} 

import React from "react";
import {
    Box,
    Typography,
} from "@mui/material"
import { makeStyles } from '@mui/styles';
import Layout from '../Layout/Layout';

const useStyles = makeStyles({
  
  normalText: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    textAlign: 'left',
	fontSize: "clamp(.9rem, 3vw, 1rem)",
    paddingRight:'2rem',
    flexWrap:'noWrap',
    paddingBottom:'3rem',
  },

  headerText: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: '700',
    fontStyle: 'normal',
    textAlign: 'left',
  }
});

const ContestRules = () => {
  const classes = useStyles();
    return(
        <Layout>
			<Box 
				style={{
					display:"flex", 
					backgroundColor:'#E1ECC8',
					flexDirection:"column", 
					justifyContent:'center', 
					alignItems:'flex-start', 
					marginTop:'4rem',
					paddingLeft:'1rem',
					margin:"0 auto",
					Width:'98vw',
					overflow:'hidden',
					"@media (max-width:600px)": {
						width:'94vw',
						overflow:'hidden',
					  },
				}} 
			>
				
				<Typography 
					sx={{paddingTop:'6rem'}}
					variant={'h4'} 
					className={classes.headerText}
				>
					Rules and Regulations
				</Typography> 

				<Typography
					sx={{paddingBottom:'1rem'}}
					className={classes.normalText} 
					variant="caption"
				>
					(This rulebook is applicable for IUT 11th National ICT Fest Programming Contest 2024)
				</Typography>

				<Typography 
					variant={'h5'} 
					className={classes.headerText}
				>
					Eligibility Criteria and Registration:
				</Typography>   

				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>Participants must be enrolled university students, either undergraduates or postgraduates</li>
					<li>Each team must consist of 3 members.</li>
					<li>Participant students must be within a certain academic year.</li>
					<li>Registration must be completed and all fees must be paid.</li>
					<li>All contestants are required to carry proof of studentship (valid student identification card or forwarding letter from the respective head of the department/faculty certifying their studentship) with them.</li>          
				</Typography>

				<Typography 
					variant={'h5'} 
					className={classes.headerText}
				>
					Equipment:
				</Typography>
				
				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>Each team will be provided with one PC for the contest duration.</li>
					<li>A contestant cannot bring a personal laptop, keyboard, mouse, etc.</li>
					<li>All electronic devices, including calculators, phones, smartwatches, etc., should be submitted to the volunteers before the contest.</li>
				</Typography>

				<Typography 
					variant={'h5'} 
					className={classes.headerText}
				>
					Contest Duration:
				</Typography> 

				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>The contest duration is scheduled for 5 hours.</li>
					<li>Judges may alter the duration in case of unforeseen difficulties.</li>
				</Typography>

				<Typography 
					variant={'h5'} 
					className={classes.headerText}
				>
					Problem Set:
				</Typography> 

				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>The contest will feature 10 to 15 problems. Problems will NOT be sorted by difficulty.</li>
					<li>The statements of the problems will be written in English. Statements of each of the problems can be accessed from Toph Arena after the start of the contest. A booklet containing all the statements of the contest will also be available after the start of the contest.</li>
					<li>It is encouraged for the teams to read the problem statements carefully, including problem descriptions, input formats, output formats, and constraints.</li>
					<li>Each problem will include the problem’s name, description, input format, output format, constraints, and sample test cases. Optionally, some problems may have explanations for some of the sample cases. For interactive problems, descriptions of how to interact with the system will be specified in the problem statement. For more information regarding interactive problems, please refer to the following link: https://help.toph.co/toph/interactive-problems</li>
					<li>The limits of allocated memories and CPU limits will be mentioned for each of the problems. The source code limit for each problem will be 64KB.</li>
					<li>After submitting a solution, a participating team will receive one of the following verdicts: “Accepted”, “Wrong Answer”, “Runtime Error”, “Compilation Error”, “CPU Limit Exceeded” or “Output Limit Exceeded”. The verdict “Accepted” is what a team is aiming for. The “Accepted” verdict indicates that a team has managed to solve the problem correctly. Explanations of all the other verdicts can be found under specific submissions on Toph. Explanations about the verdicts can also be found here: https://help.toph.co/toph/how-are-submissions-evaluated/</li>
					<li>The dataset for each of the problems will be comprehensive. Participants should expect the “worst-case” scenarios in the dataset according to the constraints of the problems. Despite the best effort of the judges, if it is found out that the dataset of a specific problem is weak or erroneous, the judges will update the dataset and will re-judge each submission that was judged incorrect before the mistake was found. In cases where a re-judgment is made, an announcement will be given to all the participants using Toph’s announcement system.</li>
				</Typography>

				<Typography 
					variant={'h5'} 
					className={classes.headerText}
				>
					Ranking:
				</Typography> 

				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>The contest will follow the ICPC-style scoring while judging a problem. A submission will have to correctly solve all the test cases within the given constraints of a problem in order to achieve the “Accepted” status for that problem. If a team manages to solve a problem, 20 minutes of penalty time will be added for each of the wrong submissions on that problem. Additionally, the number of full minutes taken to solve the problem successfully for the first time will be added with the penalty. Please note that the penalties will be added for a problem only after the “Accepted” status is achieved for that problem. For example, if a team solves a problem in the 79th minute after two failed submissions, then the total penalty for that problem will be 78 + 40 = 118. But if a team makes three incorrect attempts to solve the problem and does not manage to solve it eventually, no penalties will be added.</li>
					<li>The rank list of a contest will be ordered by the following conditions:</li>
					<Typography 
						className={classes.normalText} 
						variant="body2" 
						component="ul"
					>
						<li>A team that has solved more problems will be placed ahead of the teams that solved fewer problems.</li>
						<li>If two teams have solved the same number of problems, then the team that has the fewer penalties will be placed ahead.</li>
						<li>If two are still tied with the same number of solutions and same penalties, then the team that has made the latest successful submission earlier will be placed ahead.</li>
					</Typography>
				
					<li>The rank list will be frozen during the last 60 minutes of the contest. It will be published publicly during the prize-giving ceremony of the contest. A frozen rank list will not show any position updates of any teams after the fourth hour of the contest. However, all the teams will continue to receive their own verdicts during the final hour of the contest.</li>
				</Typography>

				<Typography 
					variant={'h5'} 	
					className={classes.headerText}
				>	
					Clarifications and Judge Interaction:
				</Typography> 

				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>If a contestant does not understand the problem statement clearly, she can use Toph’s clarification system to ask specific questions regarding the statement. However, a contestant must not ask for ideas related to the solution of a problem or test cases related to a problem.</li>
					<li>If a team has a problem-specific clarification request, the team should choose the problem from the drop-down list of Toph’s clarification system carefully. A contestant should also check the previous clarification requests of a specific problem to avoid duplicate clarification requests.</li>
					<li>Teams are encouraged to report any issue regarding the platform, problem statement, and test cases during the contest using Toph’s clarification system.</li>
					<li>Judges will address clarification requests and issue clarifications to everyone if necessary.</li>
					<li>If a contestant uses abusive or improper terms in the clarification system, the judges hold the right to disqualify the team.</li>
				</Typography>

				<Typography 
					variant={'h5'} 
					className={classes.headerText}
				> 
					Arena Conduct:
				</Typography> 

				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>Teams cannot leave the arena without volunteer permission.</li>
					<li>Each team will receive login credentials before the contest. A team must not share the credentials outside of its own team members.</li>
					<li>Communication with other teams or outsiders is strictly prohibited during the contest.</li>
					<li>The teams should remember that volunteers are there to help. They are trying their utmost to make sure that all the teams have a good experience. It is requested that the teams behave respectfully with everyone.</li>
				</Typography>

		
				<Typography 
					variant={'h5'} 
					className={classes.headerText}
				>
					Reference Notebooks::
				</Typography> 

				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>Teams may bring a printed reference notebook, up to 25 A4 pages.</li>
					<li>Each page must be printed on one side only and contain the team name and page number.</li>
					<li>Reference notebooks must be submitted to volunteers for verification BEFORE the contest.</li>
				</Typography>

				
				<Typography 
					variant={'h5'} 
					className={classes.headerText}
				>
					Technical Considerations:
				</Typography> 

				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>The solution to a problem must be written in a single file in a language available for this contest. The solution of a problem can be submitted using Toph’s problem-specific submission form.</li>
					<li>If not stated otherwise in the specific problem, the input should be taken from the standard input stream. Similarly, the output should be written to the standard output stream if not stated otherwise.</li>
					<li>Codes with parallel processing are not allowed.</li>
					<li>Teams using Java/Python should be careful about time and memory limits.</li>
				</Typography>

				<Typography 
					variant={'h5'} 
					className={classes.headerText}
				>
					Disqualification and Rule Enforcement:
				</Typography> 

				<Typography 
					className={classes.normalText} 
					variant="body1" 
					component="ul"
				>
					<li>Teams may be disqualified for jeopardizing the contest, tampering with the equipment, or attempting to attack the security.</li>
					<li>Distracting behavior or communication with other teams is strictly prohibited.</li>
					<li>A team can submit solutions for a problem as many times as necessary. However, any attempt to destabilize the platform may result in disqualification.</li>
					<li>During the contest, a team is not allowed to visit any site other than https://toph.co. Any indication of any such attempt will result in disqualification. However, a team will have the right to check whether other sites are visible from the team’s end before the start of the contest. If a team finds out that other sites are accessible from their computer, they should inform the volunteers or the judges immediately. </li>
					<li>Teams may be disqualified for attempting to access other computers in the network.</li>
					<li>If disrespectful and derogatory comments are found in a team’s submitted codes, they will be considered for disqualification.</li>
					<li>The decisions of the judges are final.</li>
				</Typography>  
      		</Box>
        </Layout>
    )
}

export default ContestRules;
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
	root: {
		margin: '1rem',
		padding:'.5rem',
		//padding:'max(2vh, 2rem)1.5rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},    
});

export default function ImageCard({logo}) {
	const classes = useStyles();
	return (
		<Card className={classes.root}>
			<CardMedia
				component="img"
				image={logo.imageUrl}  
			/>
		</Card>
	);
}

import * as React from 'react';
import Box from '@mui/material/Box';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom';
import Contests from './Contests';

import Layout from '../Layout/Layout';
import { useSelector } from 'react-redux';
import { selectAuthorFromList } from '../../redux/reducer';

const useStyles = makeStyles((theme) =>({
    container: {
		margin: '0 Auto',
		width:'100%',
		display:'flex',
		alignItems:'start',

		marginTop:'50px',
		gap:'1rem',
		
		"@media (max-width:600px)": {

			fontSize: "1rem",
			flexDirection:'column',
		},
    },

    card1:{
		margin:'0 auto',
		width:'50%',
		position:'sticky',
		top:0
    },
  
    text: {
		fontSize:30,
		color:'Black',
		display:'flex',
		justifyContent: 'center'
    }
}));

export default function AuthorProfile() {

    const classes = useStyles();
	const { id } = useParams();

	const author = useSelector(state=>selectAuthorFromList(state, id));
	
	return (
		<Layout>
			<Box className={classes.container}>
				<Card style={{flex:3,boxShadow:'none',margin:'0 auto',width:'99%',position:'sticky',top:0,
				marginLeft:'1rem',
				"@media (max-width:600px)": {
					position:'relative',
					
					top:-2,
					margin:"0 auto",
					height:'100dvh',
					backgroundColor:'pink'
				}, 
				}}>

					<CardMedia
						component="img"
					style={{
						margin:'0 auto',
						width:'90%',
						height:'49vh',
						"@media (max-width:600px)": {
							maxHeight:'60%',
							
							width:'50dvw'
						},
					
					}}
						image={author.image_url}
						alt="Live from space album cover"
					/>
				</Card>
				<Box sx={{flex:7,backgroundColor:'#FFFF',zIndex:9,margin:'0 auto',display: 'flex', flexDirection: 'column', width:'95%' }}>
					<CardContent style={{marginTop:'0rem',}}>
						<Typography style={{paddingTop:0, top:'0rem', paddingBottom:5}} fontWeight={'bold'} component="div" variant="h5">
							{author.name}
						</Typography>
						{/*
						<Typography 
							style={{
							paddingBottom:'1rem',
							}}
							component="div" 
							variant="body2"
							color="text.secondary" 
						>
							Verified expert in authoring problems
							
						</Typography>
						*/}
						<Typography variant="subtitle1" color="text.secondary" component="div">
							Member of Ardent Programmers since {author.join_year}
						</Typography>
						{/*
						<Typography variant="heading5" color="text.secondary" component="div" style={{backgroundColor:'',paddingBottom:'1rem'}}>
							{author.join_year}
						</Typography>
						*/}
						<Divider sx={{padding:'.005rem'}} />
						<Typography style={{paddingTop:'1rem',paddingBottom:'1rem'}} variant="body1" color="text.secondary" component="div">
							{author.intro}
						</Typography>
						<Divider/>
						<Contests contests={author.contests} problems={author.problems}/>
						
					</CardContent>      
				</Box>
			</Box>
		</Layout>
    );
  }
  
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Link from '@mui/material/Link';

export default function UpcomingContests() {

  // Render the component if it's still visible
  return (
    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Button size="small" color="blue" variant="contained">
            <Link underline="none" href="/all">
                    Show More
            </Link>            
        </Button>
    </Box>
  );
}



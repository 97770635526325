import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as Scroll } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop:'10rem',
		paddingBottom:'10rem',
		paddingRight:'2rem',
		paddingLeft:'2rem',
		width:'100vw',
		ontFamily:'"Roboto", sans-serif',
	},
	appbar: {
		background: 'none',
		display:'flex',
		justifyContent:'space-between',
	},
	appbarWrapper: {
		width: '90%',
		margin: '0 auto',
		display:'flex',
		flexDirection: 'row',
		justifyContent:'space-between',
		alignItems:'center',
		flexGrow:1,
	},
	appbarTitle: {
		flexGrow: '1',
		backgroundcolor:'blue',
		paddingTop:12,
	},
	contactText: {
		flexGrow: '1',
		color:'#0d2b67',
		fontSize: 20,
	},
	icon: {
		color: 'Gray',
		fontSize: '2rem',
	},
	colorText: {
		color: '#f5ae62',
		fontSize: "clamp(2rem, 2.5vw, 4rem)",
		flexWrap:'no-wrap',
		paddingTop:'.02rem',
		marginTop:'rem',
	},

	colorText1: {
		fontSize: "clamp(1rem, 2.5vw, 2rem)",
		color:'blue',
		fontFamily: 'Nunito',
		fontWeight:'bold',
		paddingLeft:'5rem',    
	},
	container: {
		textAlign: 'center',
	},
	title: {
		color:'#4cabb0',
		fontSize: "clamp(2.5rem, 4vw, 6rem)",
	},
	goDown: {
		color: '#5AFF3',
		fontSize: '4rem',
	},
}));

export default function Header() {
	const classes = useStyles();
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		setChecked(true);
	}, []);
	
	return (
		<div className={classes.root} id="header">
			<Collapse
				in={checked}
				{...(checked ? { timeout: 1000 } : {})}
				collapsedHeight={50}
			>
				<div className={classes.container}>
					<h1 className={classes.title}>
						Welcome to Ardent Programmers<br />
						<span className={classes.colorText}>
							We design problems to make thinking programmers
						</span>
					</h1>
					<Scroll to="upcomming" smooth={true}>
						<IconButton>
							<ExpandMoreIcon className={classes.goDown} />
						</IconButton>
					</Scroll>
				</div>
			</Collapse>
		</div>
	);
}
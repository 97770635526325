import { combineReducers } from "redux";

import {
	FETCH_AUTHOR_LIST,
	UPDATE_AUTHOR_LIST_SUCCESS,
	UPDATE_AUTHOR_LIST_FAILURE,

	RESET_LISTS,
	CLEAR_STATE,
	LOAD_AUTHOR_LIST_SUCCESS
} from "../constants";

const dummyAuthor = {
	name: "",
	image_url: "",
	id: "",
}

const initialState = {
	authorList: [dummyAuthor],
	isFetching: false,
	isUpdating: false,
	isUpdated: false,
}

const list = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_AUTHOR_LIST:
			return {
				...state,
				isFetching: true,
				isUpdating: true,
			}
		case LOAD_AUTHOR_LIST_SUCCESS:
			return {
				...state,
				authorList: action.payload,
			}
		case UPDATE_AUTHOR_LIST_SUCCESS:
			return {
				...state,
				isUpdating: false,
				isFetching: false,
				isUpdated: true,
			}    
		case UPDATE_AUTHOR_LIST_FAILURE:    
			return {
				...state,
				isUpdating: false,
				isFetching: false,
				isUpdated: false,

			}       
		case RESET_LISTS:
			return {
				isUpdating: false,
				isFetching: false,
			}
		case CLEAR_STATE:
			return {
				...initialState,
			}
		default:
			return state;
	}
};

export default combineReducers({
	list,
});

export const selectAuthorListIsFetching = state => state.list.isFetching;
export const selectAuthorListIsUpdating = state => state.list.isUpating;
export const selectAuthorListIsUpdated = state => state.list.isUpdated;
export const selectAuthorList = state => state.list.authorList || [];

export const selectAuthorFromList = (state, id) => {
	const authors = selectAuthorList(state);
	for (let i = 0 ; i < authors.length; i ++ ) {
		if( authors[i].id === id ) {
			return authors[i];
		}
	}
	return dummyAuthor;
} 

import React from "react";
import Slider from "react-slick";
import { makeStyles } from '@material-ui/core/styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'

const useStyles = makeStyles({
	img: {
		margin: '.5rem',
		padding:'4rem',
		//padding:'max(2vh, 2rem)1.5rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
        height:'25px'
	},    

    text: {
		fontFamily:'"Roboto", sans-serif',
		fontSize: "clamp(1.5rem, 6vw, 2rem)",
		color:'Black',
		display:'flex',
		justifyContent: 'center',
		textAlign:'center',
		backgroundColor:'#FFFF',
	}
});

export default function CarouselOfClients(){
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            }
          },
         
        ]
      };
      const classes = useStyles();
      
      return(
        <div style={{overflowY: 'hidden',backgroundColor:"#FFFF", padding:'2rem'}}className="container">
            <h1 className={classes.text}>
				Institutions who choose us for designing programming problems and tech support
			</h1>
        <Slider {...settings}>
        
        <div style={{display:'flex',flexDirection:'column'}} className='card'>
            <img src='https://i.ibb.co/JBHqZht/Furqan-Software.png' alt=''/>
            <h2>FURQAN SOFTWARE</h2>
          </div>

          <div className='card'>
            <img src='https://i.ibb.co/mS4k3Rv/IUT.jpg' alt=''/>
          </div>

          <div className="card">
            <img src='https://i.ibb.co/jLLTmgy/NSU.png' alt=''/>
          </div>

          <div className="card">
            <img src='https://i.ibb.co/txRvx7G/LU.jpg' alt=''/>
          </div>

          <div className="card">
            <img src='https://i.ibb.co/LgnxdCk/DIU.jpg' alt=''/>
          </div>

          <div className="card">
            <img src='https://i.ibb.co/64kSTFQ/VU.png' alt=''/>
          </div>

          <div className="card">
            <img src='https://i.ibb.co/NNkyGnL/BDOSN.png' alt=''/>
          </div>

          <div className="card">
            <img src='https://i.ibb.co/hCrcLvd/SUB.png' alt=''/>
          </div>

          <div className="card">
            <img src='https://i.ibb.co/vzdQqwV/images.png' alt=''/>
          </div>

          <div className="card">
            <img src='https://i.ibb.co/TbVxDbz/DRMC.jpg' alt=''/>
          </div>
        </Slider>
      </div>
      );
}
import { combineReducers } from "redux";
import user, * as fromUser from "./user";
import author, *as fromAuthor from "./author";
import authorList,*as fromAuthorList from "./authorList";
import contestList,*as fromContestList from "./contestList";

export const rootReducer = combineReducers({
    "user": user,
    "author": author,
    "authorList": authorList,
    "contestList": contestList,
});

const reducerFunction = (state, action) => rootReducer(state, action);
export default reducerFunction;


export const selectID = state => fromUser.selectUserID(state.user);
export const selectToken = state => fromUser.selectToken(state.user);
export const selectIsLoggedIn = state => fromUser.selectIsLoggedIn(state.user);
export const selectIsAdmin = state => fromUser.selectIsAdmin(state.user);
export const selectProfile = state => fromUser.selectProfile(state.user);

export const selectAuthorListIsFetching = state => fromAuthorList.selectAuthorListIsFetching(state.authorList);
export const selectAuthorListIsUpdating = state => fromAuthorList.selectAuthorListIsUpdating(state.authorList);
export const selectAuthorListIsUpdated = state => fromAuthorList.selectAuthorListIsUpdated(state.authorList);
export const selectAuthorList = state => fromAuthorList.selectAuthorList(state.authorList);
export const selectAuthorFromList = (state, id) => fromAuthorList.selectAuthorFromList(state.authorList, id)

export const selectAuthorIsFetching = state => fromAuthor.selectIsFetching(state.author);
export const selectAuthorIsUpdating = state => fromAuthor.selectIsUpdating(state.author);
export const selectAuthorIsUpdated = state => fromAuthor.selectIsUpdated(state.author);
export const selectAuthorID = state => fromAuthor.selectAuthorID(state.author);
export const selectAuthorSuccessOk = state => fromAuthor.selectAuthorSuccessOk(state.author);

export const selectContestListIsFetching = state => fromContestList.selectContestListIsFetching(state.contestList);
export const selectContestListIsUpdating = state => fromContestList.selectContestListIsUpdating(state.contestList);
export const selectContestListIsUpdated = state => fromContestList.selectContestListIsUpdated(state.contestList);
export const selectContestList = state => fromContestList.selectContestList(state.contestList);
export const selectContestFromList = (state, id) => fromContestList.selectContestFromList(state.contestList, id)

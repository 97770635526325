import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import { useEffect } from "react";
import { useSelector, useDispatch} from "react-redux";

import Link from '@mui/material/Link';
import { selectContestList } from '../../redux/reducer';
import { getContestList } from '../../redux/thunk/getContestList';
import Layout from '../Layout/Layout';

export default function ResponsiveGrid() {
  
    const dispatch = useDispatch();
	const all_authors = useSelector(state=>selectContestList(state));
	
	useEffect(() => {
		dispatch(getContestList(0,10));
	}, []);

  return (
	<Layout>
		<Box sx={{width:'100vw',flexGrow: 1 }}>
			<Grid container  columns={{ xs: 4, sm: 4, md: 12 }}>
				{all_authors.map((obj,index) => (
					<Grid sx ={{
							filter:'grayscale(100%)',
							containerType: 'inline-size',
							width: 'min(100% - 3rem, 800px)',
							display:"flex",
							justifyContent:'center',
							alignItems:'center',
							margin:'2rm',
							padding:'2rem',
							gap:'1fr',

						}} 
						item xs={6} sm={6} md={6} 
						key={index}
					>
						<Card sx ={{
							filter: 'grayscale(100%)',
							maxWidth: '300rem', 
							display:'flex',
							flexDirection:'column',
							flexBasis:'400px'
						}}>
								
							<CardMedia
								
								style={{
									width:'90%',
									objectFit:'contain',
									border:'0.31rem solid red',
									marginInline:'auto',
								}}

								component="img"
								alt={obj.name}
								image={obj.image_url}
								title={obj.name}
								
							/>
				
						<CardContent 
							style={{height:'max(120px,10vh)'}}
						>
							<Typography sx={{ fontSize: "clamp(2rem, 2.5vw, 4rem)",}} gutterBottom variant="h7" component="h2">
								{obj.name}
							</Typography>
						</CardContent>
				
				<CardActions
				>
					<Button sx={{ backgroundColor: 'black' }} size="small" variant="contained">
						<Link underline="none"href={obj.standings}>Standings</Link>
					</Button >
					<Button size="small" color="#A0C49D" variant="contained">
						<Link underline="none" href={obj.landing_url}>
							Contest
						</Link>
					</Button >
				</CardActions>
			</Card>
		</Grid>

		))}
		</Grid>
	</Box>
	</Layout>
  );
}

import * as React from 'react';
import { useEffect } from "react";
import { useSelector, useDispatch} from "react-redux";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { ListItemButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import Layout from '../Layout/Layout.js';
import { selectAuthorList } from '../../redux/reducer/index.js';
import { getAuthorList } from '../../redux/thunk/fetchAuthorList.js';

export default function Authors() {

	const dispatch = useDispatch();
	const all_authors = useSelector(state=>selectAuthorList(state));
	const specificLink = "/Authors/AuthorProfile/";
	
	const navigate = useNavigate();
	
	useEffect(() => {
		dispatch(getAuthorList(0,5));
	}, []);

	const handleClick = (id) => {
		navigate(specificLink+`${id}`);
	  };
	
	return (
		<Layout>
			<Box 
				sx={{
					width:'98vw',
					margin:'0 auto',
					marginTop:'65px',
				}}	
			>
			
				<List 
					sx={{ 
						display:'flex', 
						flexDirection:'column', 
						alignItems:'flex-start',
						justifyContent:'center',
						width:'90vw',
						width:'100dvw',
						margin:'0 auto',
					}}
				>
					{all_authors.map((obj,index) => (
						<>
							<ListItemButton 
									sx={{   
										display:'flex', 
										flexDirection:'row', 
										justifyContent:'center',
										width:'95vw',
										width:'95dvw', 
										margin:'0 auto',

										'&:hover': {
											backgroundColor:'#E1ECC8'
										},
									}} 
									key={obj.id}
									onClick = {()=>handleClick(obj.id)}									
								>
									<ListItemAvatar>
										<Avatar 
											alt="Remy Sharp" 
											src={obj.image_url} 
											sx={{ 
												width: 56, 
												height: 56, 
												borderRadius:'5px',
												marginLeft:'1rem' 
											}}
										/>
									</ListItemAvatar>
									<ListItemText
										style={{marginLeft:'auto',
										}}
										primary={
											<Box 
												sx={{
													display:'flex', 
													flexDirection:'row', 
													justifyContent:'space-between',
													"@media (max-width:600px)": {	
													},
												}}
											>
												<Typography 
													sx={{ 
														display: 'inline',
														marginLeft:'2rem',
														textDecoration:'none',
													}}
													component="span"
													variant="body2"
													color="text.primary"	
												>
													{obj.name}
												</Typography>

												<Typography
													sx={{
															display: 'inline',
															marginLeft:'auto',
													}}
													component="span"
													variant="body2"
													color="text.primary"
												>
													{obj.problems && obj.problems.length}
												</Typography>
											</Box>
										}									
									/>
									<Divider/>
								</ListItemButton>
							</>
						))}
					<Divider variant="inset" component="li" />
				</List>
			</Box>
		</Layout>
	);
}
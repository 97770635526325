import React from "react";
import {
    AppBar,
    Toolbar,
    Divider,
    Box,
	Button,
    Drawer,
    IconButton,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {NavLink} from "react-router-dom";
import Logo from '../../Constants/Images/ARDENT_LOGO.png';
import { useState } from "react";

const pages = [
	{
		link:'/',
		PageName:'Home',
	},
	{
		link:'/ContestRules',
		PageName:'ContestRules',
	},
	{
		link:'/Authors',
		PageName:'Authors',
	},


];

const Header = () => {
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  	};

	const drawer = (
		<Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
			<Box
				color={"goldenrod"}
				//variant="h6"
				component="div"
				sx={{display:'flex', flexDirection:"column", alignItems:'flex-start', flexGrow: 0,my: 2, ml: 2, pl:1 }}
			>
				<img src={Logo} alt="logo" height={"40"} width="120" />
			</Box>
			<Divider />
			<Box sx={{width:'100%', flexGrow:1, marginLeft:2, display: {xs:'block',sm:'block' } }}>
							{pages.map((page) => (
								<>
								<Button
									key={page}
									
									sx={{my: 1, color: 'white', display: 'block' }}
								>
									
									<NavLink activeClassName="active" to={page.link} style={{color:'#618264', fontFamily: '"Lato", sans-serif', fontStyle:'normal', textDecoration:"none"}}>{page.PageName}</NavLink>
								</Button>
								<Divider />
								</>
            				))}
			</Box>
		</Box>
  );

    return(
		<>
			<Box sx={{width:'100%'}}>
				<AppBar elevation={0} component={'nav'} sx={{backgroundColor:'#B0D9B1'}}>
					<Toolbar>
						<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						sx={{
							mr: 2,
							display: { sm: "none" },
						}}
						onClick={handleDrawerToggle}
						>
							<MenuIcon />
						</IconButton>
						<div 
							sx={{
								flexGrow: '1', 
								backgroundcolor:'blue', 
								paddingTop:12,
							}}
						>
							<img  
								sx={{
									width:4,
									height:4,
									padding:4,
									paddingTop:18,
								}} 
								src={Logo} 
								alt="Logo of Ardent Programmers"
								width={100} 
								height={50} 
							/>
						</div>
     
						<Box 
							sx={{ 
								flexGrow: 1, 
								marginLeft:4, 
								display: {xs:'none',sm:'none', md: 'flex' }
							 }}
						>

						
							<Button 
								sx={{
									my: 2, 
									color: 'white', 
									display: 'block' 
								}}
							>
								<NavLink 
									style={{
										color:'#618264', 
										fontFamily: '"Lato", sans-serif', 
										fontStyle:'normal', 
										textDecoration:"none"
									}}

									activeClassName="active" 
									to={"/"}
								>
									Home
								</NavLink>
							</Button>

							<Button 
								sx={{
									my: 2, 
									color: 'white',
									display: 'block' 
								}}>
								<NavLink 
									style={{
										color:'#618264', 
										fontFamily: '"Lato", sans-serif', 
										fontStyle:'normal', 
										textDecoration:"none"
									}}  
									activeClassName="active" 
									to={"/ContestRules"}
								>
									ContestRules
								</NavLink>
							</Button>
            			
							<Button 
								sx={{
									my: 2, 
									color: 'white', 
									display: 'block' 
								}}
							>
								<NavLink 
									style={{
										color:'#618264', 
										fontFamily: '"Lato", sans-serif', 
										fontStyle:'normal', 
										textDecoration:"none"
									}} 
									activeClassName="active" 
									to={"/Authors"}
								>
									Authors
								</NavLink>
							</Button>
						{/*}
							<Button 
								sx={{
									my: 2, 
									color: 'white', 
									display: 'block' 
								}}
							>
								<NavLink 
									style={{
										color:'#618264', 
										fontFamily: '"Lato", sans-serif', 
										fontStyle:'normal', 
										textDecoration:"none"
									}} 
									activeClassName="active" 
									to={"/CreateBlogPost"}
								>
									CreateBlogPost
								</NavLink>
							</Button>
								*/}
						</Box>
						{/*
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							sx={{
								mr: 2,
								display: { sm: "none" },
							}}
				//onClick={handleDrawerToggle}
						>
							<MenuIcon />
						</IconButton>
						*/}
					</Toolbar>		
				</AppBar>
				
				<Box component="nav">
					<Drawer
						variant="temporary"
						anchor="top"
						hideBackdrop="true"
						transitionDuration={{ enter: 5, exit: 5 }} 
						open={mobileOpen}
						onClose={handleDrawerToggle}
						sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: "440px",
							height:'100%',
						},
						}}
					>
						{drawer}
					</Drawer>
        		</Box>

			</Box>
		</>
    )
}

export default Header;
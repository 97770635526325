import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import Banners from './Banners/index';
import Link from '@mui/material/Link';

export default function ResponsiveGrid() {
  
  return (
		<Box sx={{width:'100vw',flexGrow: 1 }}>
			<Grid container  columns={{ xs: 4, sm: 4, md: 12 }}>
				{Banners.map((obj,index) => (
					<Grid 
						sx ={{
							filter:'grayscale(100%)',
							containerType: 'inline-size',
							width: 'min(100% - 3rem, 800px)',
							display:"flex",
							justifyContent:'center',
							alignItems:'center',
							margin:'2rm',
							padding:'2rem',
							gap:'1fr',
						}} 
						item xs={6} sm={6} md={6} 
						key={index}
					>
						<Card sx ={{
							filter: 'grayscale(100%)',
							maxWidth: '300rem', 
							display:'flex',
							flexDirection:'column',
							flexBasis:'400px'
						}}>
								
							<CardMedia
								
								style={{
									width:'90%',
									objectFit:'contain',
									border:'0.31rem solid red',
									marginInline:'auto',
								}}

								component="img"
								alt="Contest Banner"
								image={obj.imageUrl}
								title="Contest Banner"		
							/>
				
							<CardContent 
								style={{height:'max(120px,10vh)'}}
							>
								<Typography sx={{ fontSize: "clamp(2rem, 2.5vw, 4rem)",}} gutterBottom variant="h7" component="h2">
								{obj.title}
								</Typography>
							</CardContent>
				
							<CardActions
							>
								<Button sx={{ backgroundColor: 'black' }} size="small" variant="contained">
									<Link underline="none"href={obj.stadings}>Standings</Link>
								</Button >
								<Button size="small" color="#A0C49D" variant="contained">
									<Link underline="none" href={obj.contest}>
										Contest
									</Link>
								</Button >
							</CardActions>
						</Card>
					</Grid>

				))}
			</Grid>
		</Box>
	);
}

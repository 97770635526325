import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageCard from './Institution/index';
import Logos from './Logos';

const useStyles = makeStyles((theme) => ({
	container:{
		display: 'flex',
		flexDirection:'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor:'#FFFF',
		width:'100vw',
	},
	root: {
		width:'100vw',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop:'2rem',
		paddingBottom:'2rem',
		paddingLeft:'1rem',
		paddingRight:'1rem',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
		backgroundColor:"white",
	},

	text: {
		fontFamily:'"Roboto", sans-serif',
		fontSize: "clamp(1.5rem, 6vw, 2rem)",
		color:'Black',
		display:'flex',
		justifyContent: 'center',
		textAlign:'center',
		backgroundColor:'#FFFF',
	}
}));

export default function Institutions() {
	const classes = useStyles();
  
	return (
		<div className={classes.container} >
			<h1 className={classes.text}>
				Institutions who choose us for designing programming problems and tech support
			</h1>
			<div className={classes.root}>
				<ImageCard logo={Logos[0]} />
				<ImageCard logo={Logos[1]} />
				<ImageCard logo={Logos[2]} />
				<ImageCard logo={Logos[3]} />
				<ImageCard logo={Logos[7]} />
				<ImageCard logo={Logos[5]} />
				<ImageCard logo={Logos[6]} />
				<ImageCard logo={Logos[4]} />   	
			</div>
		</div>
  	);
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Background from '../../Constants/Images/FINAL_BACKGROUND.jpg';
import Contests from '../Best6/index'; 
import Overview from '../Overview1/index';
import Institutions from '../Institutions/index';
import ShowMore from '../ShowMore/index';
import Welcome from '../Header/index';
import UpcomingContests from '../UpcomingContests';
import ContactInfo from '../ContactInformation';
import Photos from '../Photos';
import Clients from '../ClientsOfArdentProgrammers';

import Layout from '../Layout/Layout';
//import Car from '../Car/index';

const useStyles = makeStyles((theme) =>({
	root: {
		backgroundImage: `url(${Background})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		width:'100dvw',
		margin:'0 auto',
		overflowX: 'hidden',
	},
}));

export default function Home() {
	const classes = useStyles();
	
	return (
		<Layout>
			<div className={classes.root} disableEqualOverflow={'true'}>
				<CssBaseline/>
				<Welcome/>
				<Photos/>
				<UpcomingContests/>
				<ContactInfo/>
				<Contests/>
				<ShowMore />
				<Overview/>
				{/*<Institutions/>
				<Clients/>*/}
				<Clients/>
			</div>
		</Layout>
	);
}


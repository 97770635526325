import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const Banners = [
	{
		title: 'Above 60 Contests',
	},
	{
		title: 'More Than 600 Problems',
	},
	{
		title: '70 Ardent Authors',
	},
	{ 
		title: 'Near 1000  Participants',
	},
];

export default function Overview1() {
	return (
		<Box sx={{width:'90vw',padding:'2rem',marginInline:'auto' }}>
			<Grid container spacing={{ xs: 2, sm:2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
				{Banners.map((obj,index) => (
					<Grid item xs={12} sm={12} md={3} key={index}>
						<Card sx={{ paddingTop:'2rem',paddingBottom:'2rem' }}>
							<CardActionArea>
								<CardContent>
									<Typography 
										sx={{fontFamily:'"Roboto", sans-serif',
												textAlign:'center',
												}} gutterBottom variant="h5" component="div">
										{obj.title}
									</Typography>		
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				))}
			</Grid>
		</Box>
	);
}

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

export default function Contests({contests, problems}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
          '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Problem</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {problems.map((obj,index) => (
            <>
            <Divider variant="middle" />
            <Typography sx={{padding:'1rem',width:'100%'}}>
                {obj.name}
            </Typography>
            
            </>
        ))}
        </AccordionDetails>
      </Accordion>
      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Contests</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {contests.map((obj,index) => (
            <Box sx={{padding:"1rem",width:'100%'}}>
            <Divider variant="middle" />
            <Typography >
                {obj.name}
            </Typography>
            
            </Box>
        ))}
        </AccordionDetails>

      </Accordion>
    </div>
  );
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Define custom styles using makeStyles

const useStyles = makeStyles({

	container:{
		display: 'flex',
		flexDirection:'column',
		justifyContent: 'center',
		alignItems: 'center',
		width:'100vw',
		backgroundColor:'#E1ECC8',
	},
    
	arrow: {
		position: 'relative',
		width: '15rem', // Adjust the length of the arrow
		height: '1px', // Adjust the thickness of the arrow
		backgroundColor: 'black', // Adjust the color of the arrow
		"@media (max-width:600px)": {
			width:'1px',
			height:'100px',
			backgroundColor:'black',
		}
	},

	text1: {
		fontFamily:'"Roboto", sans-serif',
		fontSize: "clamp(1.5rem, 6vw, 2rem)",
		color:'Black',
		display:'flex',
		justifyContent: 'center',
		textAlign:'center',
		backgroundColor:'#FFFF',
		padding:'2rem',
	},     
});

function RotatingIcon() {
	const isSmallScreen = useMediaQuery('(max-width:600px)');

	return (
		<div 
			style={{  
				display: 'inline-block', 
				justifyContent:'center', 
				transform: isSmallScreen ? 'rotate(90deg)' : 'none' 
			}
		}>
			<ArrowForwardIosSharpIcon />
		</div>
	);
}

function ManualArrow() {

	const classes = useStyles();

	return (
		<>
			<Box className={classes.arrow}>
			</Box>
			<RotatingIcon/>
		</>
	);
}


const commonStyles = {
	bgcolor: 'background.paper',
	borderColor: 'text.primary',
	m: 1,
	border: 1,
	width: '5rem',
	height: '5rem',
};

export default function Contactinfo() {
	const classes = useStyles();
	return (

	<Box className={classes.container}>
		<h1 className={classes.text}>How you can contact with us</h1>
		<Box 
			sx={{
				display:'flex',
				backgroundColor:'#E1ECC8',
				flexDirection:"column",
				justifyContent: 'center',
				alignItems:'center',
				marginInline:'auto',
				paddingTop:'4rem',
				paddingBottom:'4rem',

				"@media (max-width:600px)": {
					flexDirection:"row",
					marginInline:'auto',
					justifyContent: 'center',
					alignItems:'center',
					paddingRight:'1rem',
					paddingLeft:'2rem',
				}		
			}}
		>
			<Box 
				sx={{ 
				width:'95vw',
				display: 'flex', 
				flexDirection:'row',
				justifyContent: 'center',
				alignItems:'center',
						
				"@media (max-width:600px)": {
					width:'30dvw',
					
					flexDirection:'column',
					justifyContent:'center',
					alignItems:'center',
					} 
				}}
			>
				<Box sx={{ ...commonStyles, borderRadius: '50px' }}>
					<Typography sx={{ textAlign: 'center', padding: '.5rem' }}>
						1
					</Typography>
				</Box>
				<ManualArrow/>
				<Box sx={{ ...commonStyles, borderRadius: '4rem' }}>
				<Typography sx={{ textAlign: 'center', padding: '.5rem' }}>
					2
				</Typography>
				</Box>
				<ManualArrow/>
				<Box sx={{ ...commonStyles, borderRadius: '50px' }}>
				<Typography sx={{ textAlign: 'center', padding: '.5rem' }}>
					3
				</Typography>
				</Box>
			</Box>

			<Box sx={{ 
				width:'95vw',
				display: 'flex', 
				flexDirection:'row',
				justifyContent: 'space-around',
				alignItems:'center',
				marginInline:'auto',
				paddingTop:'2rem',
				paddingBottom:'2rem',
				"@media (max-width:600px)": {
					width:'80dvw',
					flexDirection:'column',
					justifyContent:'flex-start',
					alignItems:'flex-start',
					paddingLeft:'1rem',
					gap:'12rem',
				} 
			}}>
				<Typography 
					sx={{
						textAlign:'center', 
						fontFamily: '"Roboto", sans-serif',
						fontWeight: 700,
						fontStyle: 'normal',
					}
				}>
					Mail us on tarifezaz@gmail.com
				</Typography> 
				<Typography 
					sx={{	
						textAlign:'center',
						fontFamily: '"Roboto", sans-serif',
						fontWeight: 700,
						fontStyle: 'normal',
					}}
				>
					Get quote from Ardent Programmers
				</Typography> 
				<Typography
					sx={{	
						textAlign:'center',
						fontFamily: '"Roboto", sans-serif',
						fontWeight: 700,
						fontStyle: 'normal',	
					}}
				>
					Let's make it happen!							
				</Typography> 
				
			</Box>
		</Box>
	</Box>
);
}

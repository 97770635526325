const Banners = [
    {
      imageUrl:'https://i.ibb.co/THB6yky/IUT-ICT-FEST.webp',
      title: 'IUT 10th ICT Fest Programming Contest',
      stadings: "https://toph.co/c/iut-ict-fest-2019/standings",
      contest: "https://toph.co/c/iut-ict-fest-2019",
    },

    {
      imageUrl:'https://i.ibb.co/thCZ5zF/Criterion-Round1.webp',
      title: 'Criterion 2020 Round 1',
      stadings: "https://toph.co/c/criterion-2020-round-1/standings",
      contest: "https://toph.co/c/criterion-2020-round-1",
    },
    {
      imageUrl:'https://i.ibb.co/23ZkWRg/Toph-Duo.webp',
      title: 'Tough Duo, April 2022',
      stadings: "https://toph.co/c/tough-duo-april-2022/standings",
      contest: "https://toph.co/c/tough-duo-april-2022",
    },
    
    { 
      imageUrl:'https://i.ibb.co/pWzYKpn/1673958511873551716-4901797150008584158-849f63dcb331918773f6f5045384cdef.webp',
      title: 'Cefalo SUST Inter University Programming Contest 2023',
      stadings: "https://toph.co/c/sust-inter-university-2023/standings",
      contest: "https://toph.co/c/sust-inter-university-2023",
    },

    {
      imageUrl:'https://i.ibb.co/P9XWfkV/NHSPC2021.webp',
      title: 'National High School Programming Contest 2021 (Senior)',
      stadings: "https://toph.co/c/national-high-school-2021-senior/standings",
      contest: "https://toph.co/c/national-high-school-2021-senior",
    },

    {
      imageUrl:'https://i.ibb.co/qBmWR7S/NGPC2021.webp',
      title: 'National Girls\' Programming Contest 2021',
      stadings: "https://toph.co/c/ngpc-2021/standings",
      contest: "https://toph.co/c/ngpc-2021",
    },
  ];
  
  export default Banners;
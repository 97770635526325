export const CREATE_AUTHOR = "CREATE_AUTHOR";
export const REQUEST_FETCH_PROFILE = "REQUEST_FETCH_PROFILE";
export const CREATE_AUTHOR_SUCCESS = "CREATE_AUTHOR_SUCCESS";
export const CREATE_AUTHOR_FAILURE = "CREATE_AUTHOR_FAILURE";
export const CLEAR_STATE = "CLEAR_STATE";

export const FETCH_AUTHOR_LIST = "FETCH_AUTHOR_LIST";
export const UPDATE_AUTHOR_LIST_SUCCESS = "UPDATE_AUTHOR_LIST_SUCCESS"; 
export const UPDATE_AUTHOR_LIST_FAILURE = "UPDATE_AUTHOR_LIST_FAILURE";

export const FETCH_PENDING_AUTHOR_LIST = "FETCH_PENDING_AUTHOR_LIST"; 
export const LOAD_PENDING_AUTHOR_LIST_SUCCESS = "LOAD_PENDING_AUTHOR_LIST_SUCCESS"; 
export const LOAD_PENDING_AUTHOR_LIST_FAILURE = "LOAD_PENDING_AUTHOR_LIST_FAILURE"; 

export const LOAD_AUTHOR_LIST_SUCCESS = "LOAD_FINISHED_AUTHOR_LIST_SUCCESS";
export const LOAD_AUTHOR_LIST_FAILURE = "LOAD_FINISHED_AUTHOR_LIST_FAILURE";
export const RESET_LISTS = "RESET_LISTS";

export const READ_AUTHOR_FAILURE = "READ_AUTHOR_FAILURE";
export const READ_AUTHOR_SUCCESS = "READ_AUTHOR_SUCCESS";

export const FETCH_CONTEST_LIST = "FETCH_CONTEST_LIST";
export const LOAD_CONTEST_LIST_SUCCESS = "LOAD_CONTEST_LIST_SUCCESS";
export const LOAD_CONTEST_LIST_FAILURE = "LOAD_CONTEST_LIST_FAILURE";
export const UPDATE_CONTEST_LIST_SUCCESS = "UPDATE_CONTEST_LIST_SUCCESS"; 
export const UPDATE_CONTEST_LIST_FAILURE = "UPDATE_CONTEST_LIST_FAILURE"; 
//export const UPDATE_CONTEST_LIST_SUCCESS = "LOAD_FINISHED_AUTHOR_LIST_SUCCESS";
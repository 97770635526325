import axios from "axios";

import {
	fetchAuthorList,
	loadAuthorListSuccess,
	loadAuthorListFailure
} from "../actionCreator/index";
import { BASE_URL } from "../constants/config";

export const getAuthorList = ( skip, limit ) =>  async (dispatch,getState) => {
	//if(isFetching === false && isUpdated === false) {
		dispatch(fetchAuthorList());

		axios.get(BASE_URL+"/ardent/v1/author/list/"+skip+"/"+limit,{
		})
		.then((response)=>{
			return dispatch(loadAuthorListSuccess(response.data))
		}, error=>{
			return dispatch(loadAuthorListFailure(error))
		})
	//}
}


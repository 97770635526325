import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => {
	return (
		<div sx={{maxWidth:'100%',overflowX: 'hidden',}}>
			<Header />
			<div>{children}</div>
			<Footer />
		</div>
	); 
};

export default Layout;
import { 
//        REQUEST_LOGIN,
  //      SUCCESS_LOGIN,
    //    FAILURE_LOGIN,
      //  SUCCESS_LOGOUT,
        //CREATE_AUTHOR,
        CREATE_AUTHOR_SUCCESS,
        CREATE_AUTHOR_FAILURE,
    
        FETCH_PENDING_AUTHOR_LIST,
        LOAD_PENDING_AUTHOR_LIST_SUCCESS,
        LOAD_PENDING_AUTHOR_LIST_FAILURE,
    
        FETCH_AUTHOR_LIST,
        LOAD_AUTHOR_LIST_SUCCESS,
        LOAD_AUTHOR_LIST_FAILURE,
        RESET_LISTS,
        //UPDATE_SESSION_EXPIRY,
        READ_AUTHOR_SUCCESS,
        READ_AUTHOR_FAILURE,
        CLEAR_STATE,
        FETCH_CONTEST_LIST,
        LOAD_CONTEST_LIST_SUCCESS,
        LOAD_CONTEST_LIST_FAILURE,
        //UPDATE_SESSION_EXPIRY,
    } from "../constants";
    
    /*
    export const createAuthor = () =>({
        type: CREATE_AUTHOR,
    })
        
    export const requestLogin = () => ({
        type : REQUEST_LOGIN,
    })
    
    export const loginSuccess = (payload) => ({
        type: SUCCESS_LOGIN,
        payload: payload,
    })
    
    export const updateSessionExpiry = (nextDay) => ({
        type: UPDATE_SESSION_EXPIRY,
        payload: nextDay,
    })
    
    export const loginFailure = () => ({
        type : FAILURE_LOGIN,
    })
    
    export const logout = () => ({
        type: SUCCESS_LOGOUT,
    })
    */
    export const createAuthorSuccess = (data) => ({
        type: CREATE_AUTHOR_SUCCESS,
        payload: data,
    })
        
    export const createAuthorFailure = () => ({
        type : CREATE_AUTHOR_FAILURE,
    })
        
    export const loadPendingAuthorListSuccess = (data) => ({
        type: LOAD_PENDING_AUTHOR_LIST_SUCCESS,
        payload: data,
    })
    
    export const loadPendingAuthorListFailure = () => ({
        type : LOAD_PENDING_AUTHOR_LIST_FAILURE,
    })
        
    export const fetchPendingAuthorList = () => ({
        type: FETCH_PENDING_AUTHOR_LIST,
    })
        
    export const loadAuthorListSuccess = (data) => ({
        type: LOAD_AUTHOR_LIST_SUCCESS,
        payload: data,
    })
    
    export const loadAuthorListFailure = () => ({
        type : LOAD_AUTHOR_LIST_FAILURE,
    })
    
    export const fetchAuthorList = () => ({
        type: FETCH_AUTHOR_LIST,
    })
    
    export const resetLists = () => ({
        type: RESET_LISTS,
    })
        
    export const clearState = () => ({
        type : CLEAR_STATE,
    });

    export const readAuthorSuccess = (payload) => ({
        type: READ_AUTHOR_SUCCESS,
        payload: payload,
    })
    
    export const readAuthorFailure = () => ({
        type : READ_AUTHOR_FAILURE,
    })
    
    export const loadContestListSuccess = (data) => ({
        type: LOAD_CONTEST_LIST_SUCCESS,
        payload: data,
    })
    
    export const loadContestListFailure = () => ({
        type : LOAD_CONTEST_LIST_FAILURE,
    })
    
    export const fetchContestList = () => ({
        type: FETCH_CONTEST_LIST,
    })
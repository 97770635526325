import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@material-ui/core/Button';
import Link from '@mui/material/Link';

export default function UpcomingContests() {
	// State to manage visibility of the component
	const [visible, setVisible] = useState(true);

	// Calculate the date when the component should expire
	const expiryDate = new Date(); // Current date
	expiryDate.setDate(expiryDate.getDate() + 1); // Add 7 days

	// Check if the current date is past the expiry date
	useEffect(() => {
		const timer = setTimeout(() => {
			const currentDate = new Date();
			if (currentDate > expiryDate) {
			setVisible(false); // Hide the component if past expiry date
			}
		}, 1000); // Check every second
		return () => clearTimeout(timer);
	}, [expiryDate]);

	// Render the component if it's still visible
	return (
		<div style={{width:'100vw'}}>
			{visible && (
				<Box sx={{marginY:'4rem'}}>
					<Card 
						id={'upcomming'} 
						sx={{ width:'60vw',marginInline:'auto', bottom:'4rem'}}
					>
						<CardMedia
							component="img"
							sx={{filter: 'grayscale(100%)'}}
							image="https://i.ibb.co/j4hbLVd/IUT.png"
							alt="IUT 11th National ICT Fest Programming Contest 2024"
						/>
						<CardContent>
							<Typography variant="body2" color="text.secondary">
								IUT 11th National ICT Fest Programming Contest 2024
							</Typography>
						</CardContent>
						<CardActions>
							<Button size="small" color="teal" variant="contained">
								<Link underline="none" href="/ContestRules">RuleBook</Link>
							</Button >
						</CardActions>					
					</Card>
				</Box>
			)}
		</div>
	);
}



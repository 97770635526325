import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Home from './Components/Home/index';
import ContestRules from './Components/ContestRules/index';
import Contests from "./Components/Best6";
import All from "./Components/All"
import Authors from './Components/AuthorList/index';
import AuthorProfile from "./Components/AuthorProfile/index";
import { store, persistor } from "./redux";

export default function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<div style={{margin:'0 auto'}}>				
					<BrowserRouter>
						<Routes>
							<Route path="/" exact element={<Home />} />		
							<Route path="/ContestRules" element={<ContestRules />} />
							<Route path="/Authors" element={<Authors />} />
							<Route path="/Authors/AuthorProfile/:id" element={<AuthorProfile />} />
							<Route path="/Contests" element={<Contests />}/>
							<Route path="/all" element={<All />}/>
						</Routes>
					</BrowserRouter>					
				</div>
			</PersistGate>
		</Provider>
	);
}